import React from "react";
import { graphql, Link } from "gatsby";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../components/Layout";
import { changeLocale, useIntl, FormattedDate } from "gatsby-plugin-intl";
import { useEffect, useRef } from "react";
import Seo from "../components/seo";
import { TableOfContent } from "../components/tableOfContent";
import { Video } from "../components/Video";

export default function Post({ data, location }) {
  
  let timer;
  let headings = []; //storing all the different headings for passing to table of content component

  function extractAndSaveId (){
    if(location.state?.hasOwnProperty('prevUri')){
      const id = extractHash(location.state.prevUri);
      localStorage.setItem("targetID", id);
    }
  }
  
  function extractHash (prevUrl){
    if(prevUrl===undefined){
      return
    }else{
      const parts = prevUrl.split("#");
      return parts[parts.length - 1];
    }
  }

  function scrollToAnchor(location, mainNavHeight = 0) {
    if(document !== "undefined"){ //stop the build from failing 
      if (location) { // Check for location so build does not fail
        timer = setTimeout(()=>{
          if(document.getElementById(`${location}`)){
            const item = document.getElementById(`${location}`).offsetTop;
            window.scrollTo({
              top: item - mainNavHeight,
              behavior: "smooth",
            })
          }
          localStorage.removeItem("targetID");    
        }, 500);
      }
    }
  }

  // Calculating the proper scroll padding top to prevent content hiding behind the sticky header based on user device
  function calculateScrollPadding (){
    let headerElement;
    if (document !== undefined){
      if (document.querySelector(".site-head")){
        headerElement = document.querySelector(".site-head");
      }else if(document.querySelector(".site-headRTL")){
        headerElement = document.querySelector(".site-headRTL");

      }else{
        return
      }
      const height = headerElement.offsetHeight;
      let scrollPaddingTop = 0;
      if (height > 134){ // device is mobile
       scrollPaddingTop = height + 75; //extra padding added because of sticky TOC in mobile mode
      }else{
        scrollPaddingTop = height + 20; //extra padding added just to make the heading a bit away from header
      }
      return scrollPaddingTop;
    }
  }

  function addHyFont() {
    if(document !== "undefined"){ //stop the build from failing 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("HyFont");
      }
    }
  }

  function addFaFont() {
    if(document !== "undefined"){ //stop the build from failing 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("FaFont");
      }
    }
  }

  function addLatinFont() {
    if(document !== "undefined"){ //stop the build from failing
      // First removing possible Hy and Fa Font:
      if(document.querySelector(".HyFont")){
        const content = document.querySelector(".HyFont");
        content.classList.remove("HyFont");
      }
      if(document.querySelector(".FaFont")){
        const content = document.querySelector(".FaFont");
        content.classList.remove("FaFont");
      }
      // Now adding the Latin font: 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("LatinFont");
      }
    }
  }

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => {
        //console.log("children:", )
        headings.push({
          type: "h2",
          content: node.content[0].value.trim()
        });       
        return <h2 id={node.content[0].value.trim()}>{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        headings.push({
          type: "h3",
          content: node.content[0].value.trim()
        });
        return <h3 id={node.content[0].value.trim()}>{children}</h3>
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        headings.push({
          type: "h4",
          content: node.content[0].value.trim()
        });
        return <h4 id={node.content[0].value.trim()}>{children}</h4>
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        if (uri.startsWith("http") || uri.startsWith("https") ){
          return(
            <a 
              href={uri.trim()}
              >
              {children}
            </a>
          )
        }else{
          return (
            <Link 
              to={uri.trim()}
              state={{prevUri: uri.trim()}}
            >
              {children}
            </Link>
          )
        }
      },
    },
  }
  
  const image = getImage(data.contentfulBlogPost.heroImage)

  const intl = useIntl(); 
  
  const content = useRef(data.contentfulBlogPost.bodyEn)
  const title = useRef(data.contentfulBlogPost.title.trim())
  const description = useRef(data.contentfulBlogPost.summary.summary.trim())
  const videoTitle = data.contentfulBlogPost.videoTitle;
  const videoURL = data.contentfulBlogPost.videoSourceUrl;

  useEffect(()=>{
    extractAndSaveId();
    if(intl.locale !=="en"){
      changeLocale(intl.locale);
    }
    switch(intl.locale){
      case "hy":
          //adding Hy Font:
          addHyFont();
          content.current = data.contentfulBlogPost.bodyHy;
          title.current = data.contentfulBlogPost.titleHy ?
            data.contentfulBlogPost.titleHy.trim():
            data.contentfulBlogPost.title.trim();
          description.current = data.contentfulBlogPost.descriptionHy.descriptionHy ?
            data.contentfulBlogPost.descriptionHy.descriptionHy.trim():
            data.contentfulBlogPost.summary.summary.trim();
          if(localStorage.getItem("targetID") != null){
            scrollToAnchor(localStorage.getItem("targetID"), calculateScrollPadding());
          }
          break;
      case "ru":
          // removing possible Hy and Fa fonts and add Latin font:
          addLatinFont();
          content.current = data.contentfulBlogPost.bodyRu;
          title.current = data.contentfulBlogPost.titleRu ?
            data.contentfulBlogPost.titleRu.trim():
            data.contentfulBlogPost.title.trim();
          description.current = data.contentfulBlogPost.descriptionRu.descriptionRu ?
            data.contentfulBlogPost.descriptionRu.descriptionRu.trim():
            data.contentfulBlogPost.summary.summary.trim();
        
          if(localStorage.getItem("targetID") != null){
            scrollToAnchor(localStorage.getItem("targetID"), calculateScrollPadding());
          } 
          break; 
      case "fa":
          //adding Fa Font:
          addFaFont();
          content.current = data.contentfulBlogPost.bodyFa;
          title.current = data.contentfulBlogPost.titleFa ?
            data.contentfulBlogPost.titleFa.trim():
            data.contentfulBlogPost.title.trim();
          description.current = data.contentfulBlogPost.descriptionFa.descriptionFa ?
            data.contentfulBlogPost.descriptionFa.descriptionFa.trim():
            data.contentfulBlogPost.summary.summary.trim(); 
          if(localStorage.getItem("targetID") != null){
            scrollToAnchor(localStorage.getItem("targetID"), calculateScrollPadding());
          }
          break; 
      case "fr":
          // removing possible Hy and Fa fonts and add Latin font:
          addLatinFont();
          content.current = data.contentfulBlogPost.bodyFr;
          title.current = data.contentfulBlogPost.titleFr ?
            data.contentfulBlogPost.titleFr.trim():
            data.contentfulBlogPost.title.trim();
          description.current = data.contentfulBlogPost.descriptionFr.descriptionFr ?
            data.contentfulBlogPost.descriptionFr.descriptionFr.trim():
            data.contentfulBlogPost.summary.summary.trim();
          if(localStorage.getItem("targetID") != null){
            scrollToAnchor(localStorage.getItem("targetID"), calculateScrollPadding());
          }
          break;
      default:
          // removing possible Hy and Fa fonts and add Latin font:
          addLatinFont();
          content.current = data.contentfulBlogPost.bodyEn;
          if(localStorage.getItem("targetID") != null){
            scrollToAnchor(localStorage.getItem("targetID"), calculateScrollPadding());
          } 
          break;
        }
      return ()=>{
        localStorage.removeItem("targetID");
        clearTimeout(timer);
      }
  },[
    intl.locale, 
    data.contentfulBlogPost.bodyEn,
    data.contentfulBlogPost.bodyRu,
    data.contentfulBlogPost.bodyHy,
    data.contentfulBlogPost.bodyFa,
    data.contentfulBlogPost.bodyFr,
  ])

  return (
    <Layout isHome={false}>
      <div className="container">
        <article className="content">
          <h1 className="content-title">
            {/* {intl.formatMessage({id: `${data.contentfulBlogPost.title}` })} */}
            {title.current}
          </h1>
          <div className="article_update_date_parent">
            <h3 
              className="article_update_date">
              {intl.formatMessage({id: "This article has been updated on" }) }
            </h3><span>&nbsp;</span>
            <h2 className="article_update_date">
              <time>
                <FormattedDate
                  value={new Date(data.contentfulBlogPost.updatedAt)}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </time>
            </h2>
          </div>
          {videoTitle ?
            <Video 
              videoSrcURL={videoURL}
              videoTitle={videoTitle}
            /> :
            <GatsbyImage
              className="post-feature-image"
              image={image}
              alt={data.contentfulBlogPost.heroImageAlt}
            />
          }
        </article>
      </div>
      <div className="blog-container content">
        <TableOfContent list={headings} uri={location.pathname}/>
        <section className="content-body blog-content-body">
          {renderRichText(content.current, options)}
        </section>
      </div>
    </Layout>
  );
}

export const Head = ({ data, pageContext }) => (
  //console.log("data object:", data, "page context:", pageContext)
  <Seo
  //title={`${pageContext.intl.messages[`${data.contentfulBlogPost.title}`]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  brand={pageContext.intl.messages["Gritarres Law Firm"]}
  titleEn={data.contentfulBlogPost.title}
  titleFa={data.contentfulBlogPost.titleFa}
  titleFr={data.contentfulBlogPost.titleFr}
  titleHy={data.contentfulBlogPost.titleHy}
  titleRu={data.contentfulBlogPost.titleRu}
  descriptionEn={data.contentfulBlogPost.summary.summary}
  descriptionHy={data.contentfulBlogPost.descriptionHy.descriptionHy}
  descriptionRu={data.contentfulBlogPost.descriptionRu.descriptionRu}
  descriptionFa={data.contentfulBlogPost.descriptionFa.descriptionFa}
  descriptionFr={data.contentfulBlogPost.descriptionFr.descriptionFr}
  article={true}
  language={pageContext.intl.language}>
  </Seo>
)   


export const pageQuery = graphql`
query ($slug: String!) {
  contentfulBlogPost(slug: {eq: $slug}) {
    title
    date
    videoTitle
    videoSourceUrl
    bodyEn {
      raw
    }
    bodyFa {
      raw
    }
    bodyFr {
      raw
    }
    bodyHy {
      raw
    }
    bodyRu {
      raw
    }
    heroImageAlt
    heroImage {
      gatsbyImageData
    }
    updatedAt
    titleFa
    titleFr
    titleHy
    titleRu
    summary {
      summary
    }
    descriptionFa {
      descriptionFa
    }
    descriptionFr {
      descriptionFr
    }
    descriptionHy {
      descriptionHy
    }
    descriptionRu {
      descriptionRu
    }
  }
}
`;
