import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "gatsby-plugin-intl";

export const TableOfContent =({list, uri})=> {
  
  const intl = useIntl();
  const [showTOC, setShowTOC]=useState(true);

  function toggleShowTOC(){
    setShowTOC(!showTOC);
    // Conditional height for TOC for proper height in mobile mode
    // When the TOC is hide, we need the height to be fit-content, making the TOC hidden as there would not be any content
    // When the TOC is not hide, we need the height to be based on view height(vh), making the toc being showed properly and also scrollable if content is longer than view height (overflow-y: auto) 
    if(showTOC){
      if (document !== undefined){
        if (document.querySelector(".toc")){
          const toc = document.querySelector(".toc");
          toc.style.height = "fit-content";
        }
      }
    }else{
      if (document !== undefined){
        if (document.querySelector(".toc")){
          const toc = document.querySelector(".toc");
          toc.style.height = "78vh";
        }
      }
    }
    
  }

  function hideTOCBasedOnWindowWidth(){
    if (window !== undefined){
      const windowWidth = window.innerWidth;
      if (windowWidth < 680){
        setShowTOC(false);
        if (document !== undefined){
          if (document.querySelector(".toc")){
            const toc = document.querySelector(".toc");
            toc.style.height = "fit-content";
          }
        }
      }
    }
  }

  useEffect(() => {
    hideTOCBasedOnWindowWidth();
  },[]);

  return(
    <nav className="toc" aria-labelledby="secondary-navigation">
      <div className="toc-header" onClick={()=>toggleShowTOC()}>
        <FontAwesomeIcon
          className="center"
          icon={faListUl}
        />
        <h4 
          className="center" 
          id="secondary-navigation"
        >
          {intl.formatMessage({id: "Table of Contents"})}
        </h4>
        {showTOC ?
        <FontAwesomeIcon
          className="center"
          icon={faCaretUp}
        /> :
        <FontAwesomeIcon
          className="center"
          icon={faCaretDown}
        />
      }
      </div>
      {showTOC && <ol className="toc-content">
          {list.map(item=>{
            if(item.type === "h2"){
              return (
                <li className="TOCh2">
                  <a 
                    href={`${uri}#${item.content}`}
                    onClick={()=>{
                      hideTOCBasedOnWindowWidth();
                    }}
                  >
                    {item.content}
                  </a>
                </li>
              )
            }
            if(item.type === "h3"){
              return (
                <li className="TOCh3">
                  <a 
                    href={`${uri}#${item.content}`} 
                    onClick={()=>{
                      hideTOCBasedOnWindowWidth();
                    }}
                  >
                    {item.content}
                  </a>
                </li>
              )
            }
            if(item.type === "h4"){
              return (
                <li className="TOCh4">
                  <a 
                    href={`${uri}#${item.content}`}
                    onClick={()=>{
                      hideTOCBasedOnWindowWidth();
                    }}
                  >
                    {item.content}
                  </a>
                </li>
              )
            }
          })}
        </ol>
      }
    </nav>
  )
}