import React from "react"

export const Video = ({ videoSrcURL, videoTitle }) => (
    <iframe
        className="post-feature-video"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        loading="lazy"
    />
)